.form-input {
  position: relative;
  width: 100%;

  input,
  textarea, {
    width: 100%;
    padding: 11px 23px 12px;
    border-radius: 2px;
    border: 1px solid #D9D9D9;
    font-size: inherit;
    line-height: 1.3;
    box-sizing: border-box;
    resize: vertical;
    outline: none;
    min-height: 48px;

    &::placeholder {
      color: rgba(0, 0, 0, 0.25);
    }

    &:focus {
      border: 1px solid $black-focus;
    }

    &:disabled {
      background: #F4F4F4;;
    }
  }

  .rc-time-picker {
    display: block;

    .rc-time-picker-clear {
      display: none;
    }
  }

  .DayPickerInput {
    width: 100%;

    .DayPickerInput-Overlay {
      left: 15px;
    }
  }

  .form-input--placeholder {
    position: absolute;
    left: 0;
    top: 0;
    padding: 14px 23px 15px;
    color: $gray-light;
    pointer-events: none;
  }

  .form-input--message {
    color: $red;
    text-align: right;
    font-size: 1.07em;
  }

  &.form-input--error {
    input,
    textarea, {
      border-color: $red;
    }
  }
}

.rc-time-picker-panel {
  z-index: 100000 !important;
}

.input-number {
  position: relative;

  input {
    padding-right: 10px;
  }

  button:nth-of-type(1) {
    position: absolute;
    right: 11px;
    top: 9px;
    transform: rotate(180deg);
    background: white;
    z-index: 1;
  }

  button:nth-of-type(2) {
    position: absolute;
    right: 10px;
    top: 18px;
    background: white;
    z-index: 1;
  }
}

.input-week {
  display: flex;

  .input-week--item {
    width: 50px;
    height: 50px;
    background: #F4F4F4;
    border: 1px solid #F4F4F4;
    border-radius: 6px;
    margin-right: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.25em;
    cursor: pointer;
  }

  .input-week--item--selected {
    background: #E3C36F;
    border: 1px solid #E3C36F;
  }
}

.link-input {
  position: relative;
  width: 100%;

  .link-input--image {
    position: absolute;
    top: 9px;
    left: 23px;

    img {
      width: 24px;
      height: 24px;
      margin-right: 12px;
    }

    a {
      line-height: 1.3;
    }
  }

  .link-input--prefix {
    font-size: 1em;
    line-height: 1.3;
    color: $gray-light;
    margin-top: 5px;
  }

  input {
    width: 100%;
    padding: 11px 23px 12px 59px;
    border-radius: 2px;
    border: 1px solid $gray-light;
    font-size: inherit;
    box-sizing: border-box;
    outline: none;

    &::placeholder {
      color: rgba(0, 0, 0, 0.25);
    }

    &:focus {
      border: 1px solid $black-focus;
    }
  }

  .link-input--action {
    position: absolute;
    top: 16px;
    right: 23px;
    font-size: 1em;
    line-height: 1.3;
  }

  .link-input--message {
    color: $red;
    text-align: right;
    font-size: 1.07em;
  }

  &.link-input--shifted {
    input {
      padding: 14px 23px 15px 130px;
    }
  }

  &.link-input--error {
    input {
      border-color: $red;
    }
  }
}

.location-input {
  position: relative;
  width: 100%;

  .location-input--image {
    position: absolute;
    top: 13px;
    left: 23px;
    z-index: 1;

    img {
      width: 24px;
      height: 24px;
      margin-right: 12px;
    }

    a {
      line-height: 1.3;
    }
  }

  input {
    width: 100%;
    padding: 14px 23px 15px 59px;
    border-radius: 2px;
    border: 1px solid $gray-light;
    font-size: inherit;
    box-sizing: border-box;
    outline: none;
    background: white;
    box-shadow: none;

    &::placeholder {
      color: rgba(0, 0, 0, 0.25);
    }

    &:focus {
      border: 1px solid $black-focus;
    }

    &:hover {
      border: 1px solid $gray-light;

      &:focus {
        border: 1px solid $black-focus;
      }
    }
  }

  .google-places-autocomplete__suggestions-container {
    cursor: pointer;
  }

  .link-input--message {
    color: $red;
    text-align: right;
    font-size: 1.07em;
  }

  &.link-input--error {
    input {
      border-color: $red;
    }
  }
}

.input-pair {
  .input-pair--label {
    color: rgba(0, 0, 0, 0.45);
    margin-bottom: 8px;
    width: fit-content;
  }

  &.input-pair--required {
    .input-pair--label {
      position: relative;

      &:after {
        position: absolute;
        top: 0;
        right: -5px;
        content: '*';
        color: $red;
      }
    }
  }
}

.image-upload {
  width: 100%;
  display: flex;
  justify-content: center;

  .image-upload--content {
    position: relative;
    width: 90px;
    height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    background: $gray-lightest;
    overflow: hidden;

    img {
      width: 40px;
      height: 40px;
    }

    input {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 100%;
      opacity: 0;
    }

    .image-upload--content--uploaded {
      width: 90px;
      height: 90px;
    }
  }
}

.logo-upload {
  position: relative;

  .logo-upload--image {
    position: absolute;
    top: 13px;
    left: 23px;

    img {
      width: 24px;
      height: 24px;
      margin-right: 12px;
    }
  }

  .logo-upload--button {
    position: absolute;
    right: 0;
    top: 0;
    width: 96px;

    button {
      position: absolute;
      right: 0;
      top: 0;
      pointer-events: none;
      background: #E3C36F;
      box-sizing: border-box;
      border-radius: 0 2px 2px 0;
      width: 96px;
      padding: 12px 23px;
      color: #FFFFFF;
      font-weight: 500;
      font-size: 1.14em;
      line-height: 1.3;
      height: 47px;
    }

    input {
      position: absolute;
      right: 0;
      top: 0;
      opacity: 0;
      z-index: 1;
      height: 50px;
    }
  }
}

.submit-button {
  display: block;
  width: 100%;
  height: 48px;
  border-radius: 2px;
  padding: 13px 0;
  text-align: center;
  background: $yellow-dark;
  color: white;

  &:disabled {
    cursor: not-allowed;
  }
}

.blue-button {
  background: #2D8CFF;
  color: white;
  border-radius: 6px;
  padding: 7px 16px;
}

.action-button {
  background: #E3C36F;
  border-radius: 2px;
  color: white;
  padding: 5px 16px;
}

.bordered-button {
  padding: 4px 16px;
  border: 1px solid #D9D9D9;
  color: rgba(0, 0, 0, 0.65);
  border-radius: 2px;
  background: white;
  display: flex;
  align-items: center;
  white-space: nowrap;

  img {
    width: 16px;
    height: 16px;
    margin-right: 6px;
  }
}

.checkbox {
  display: flex;
  font-size: 1em;
  line-height: 1.3;
  cursor: pointer;

  img {
    margin-right: 9px;
  }

  &.checkbox--disabled {
    opacity: 0.4;
  }
}

.suffix-input {
  position: relative;
  display: flex;

  >*:nth-child(1) {
    background: #F4F4F4;
    border: 1px solid #C4C4C4;
    box-sizing: border-box;
    border-radius: 2px 0px 0px 2px;
    padding: 12px 23px;
    color: #666666;
    height: 48px;
    border-right: none;
    font-weight: 500;
  }

  input {
    border-radius: 0 2px 2px 0;
  }

  .suffix-input--status {
    position: absolute;
    right: 0;
    bottom: -24px;
    color: #38C116;
    font-size: 14px;
  }

  .suffix-input--status--error {
    color: $red;
  }
}

.search-input {
  position: relative;
  width: 170px;

  input {
    padding: 6px 16px;
    background: white;
    border: 1px solid #F0F0F0;
    box-sizing: border-box;
    border-radius: 2px;
    min-height: 32px;
  }

  img {
    position: absolute;
    right: 16px;
    top: 9px;
  }
}

.rich-text-input {
  position: relative;
  width: 100%;

  .DraftEditor-root {
    font-size: 14px;

    .DraftEditor-editorContainer {
      >* {
        padding: 11px 23px 12px !important;
      }
    }

    .public-DraftEditorPlaceholder-root {
      top: 11px;
      left: 23px;
      color: rgba(0, 0, 0, 0.25);
      padding: 0 !important;
    }
  }

  .rich-text-input--message {
    color: $red;
    text-align: right;
    font-size: 1.07em;
  }

  &.rich-text-input--error {
    .DraftEditor-root {
      border-color: $red;
    }
  }
}