.onboarding {

  .landing--content {

    .onboarding--image {
      height: 80px;
      margin-top: 35px;

      img {
        width: 80px;
        height: 80px;
        border: 2px solid #F0F0F0;
        border-radius: 2px;
      }
    }

    .input-pair {
      margin: 17px 0;
    }

    .location-input {

      .location-input--image {
        display: none;
      }

      input {
        padding: 11px 23px 12px;
      }
    }

    .link-input {

      .link-input--image {
        display: none;
      }

      input {
        padding: 11px 23px 12px;
      }
    }

    .submit-button {
      margin-top: 25px;
    }
  }
}

.pre-onboarding {

  .landing--content {

    .choose-image {
      display: flex;
      align-items: flex-start;
      color: white;
      text-align: left;

      .choose-image--logo {
        height: 80px;
        border: 2px solid #F0F0F0;
        border-radius: 2px;
        margin-right: 16px;

        img {
          width: 80px;
          height: 80px;
        }
      }

      button {
        border: 1px solid #D9D9D9;
        border-radius: 2px;
        color: #E3C36F;
        padding: 5px 16px;
      }

      input {
        display: none;
      }
    }

    .input-pair {
      margin: 16px 0 0;
    }

    .submit-button {
      margin-top: 25px;
    }
  }
}