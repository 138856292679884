.tables-page {

  .tables--content {
    max-width: none;
    display: flex;
    flex-wrap: wrap;
    margin: 0 -12px;
    height: fit-content;
    padding-bottom: 40px;
  }

  .tables--item {
    position: relative;
    width: 100%;
    max-width: 320px;
    margin: 24px 12px 0;
    padding: 16px;
    background: white;
    box-shadow: 0px 4px 4px 8px rgba(44, 50, 66, 0.001);
    border-radius: 2px;
    height: fit-content;

    img {
      width: 46px;
      height: 46px;
    }

    .dropdown {
      position: absolute;
      right: 20px;
      top: 0;

      img {
        transform: rotate(90deg);
        width: 20px;
      }
    }

    .tables--item--number {
      position: absolute;
      top: 16px;
      left: 16px;
      color: rgba(0, 0, 0, 0.65);
      font-weight: 500;
    }

    .tables--item--top,
    .tables--item--bottom {
      display: flex;
      justify-content: center;

      .tables--item--placeholder {
        margin: 0 12px;
      }
    }

    .tables--item--left,
    .tables--item--right {
      display: flex;
      justify-content: center;
      flex-direction: column;

      .tables--item--placeholder {
        margin: 12px 0;
      }
    }

    .tables--item--content {
      display: flex;
      justify-content: space-between;
      margin: 5px 0;
    }

    .tables--item--center {
      width: 160px;
      height: 160px;
      margin: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      background: #F7F8F9;
      border: 1px solid #F0F0F0;
      box-sizing: border-box;
      box-shadow: 0px 4px 4px 8px rgba(44, 50, 66, 0.001);
      border-radius: 2px;

      .tables--item--center--title {
        font-size: 0.86em;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;
        text-align: center;
      }

      img {
        width: 50px;
        height: 50px;
        margin-top: 8px;
        box-shadow: 0 4px 4px 8px rgba(44, 50, 66, 0.001);
        border-radius: 2px;
      }
    }

    .tables--item--placeholder {
      width: 48px;
      height: 48px;
      background: #F7F8F9;
      border: 1px solid #3AFED1;
      box-sizing: border-box;
      box-shadow: 0px 4px 4px 8px rgba(44, 50, 66, 0.001);
      border-radius: 2px;
    }
  }

  .tables-dialog {

    .input-pair {
      margin: 12px 0;
    }

    .logo-upload--button {
      button {
        font-size: 14px;
      }
    }

    .tables-dialog--hint {
      margin: -8px 0 12px;
      color: rgba(0, 0, 0, 0.25);
    }
  }

  .table-meeting {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: white;
    z-index: 1;

    .table-meeting--close {
      position: absolute;
      top: 5px;
      right: 15px;
      font-size: 30px;
      line-height: 30px;
      z-index: 1;
    }
  }
}