.page-webinars {

  .page-webinars--toggle {
    display: flex;
    align-items: center;
    margin-right: 13px;

    img {
      width: 22px !important;
      height: 22px !important;
    }
  }

  .page-webinars--search {
    position: relative;

    input {
      padding: 6px 16px;
      min-height: auto;
      background: #FAFAFA;
      border: 1px solid #F0F0F0;
      box-sizing: border-box;
      border-radius: 2px;

      &::placeholder {
        color: rgba(0, 0, 0, 0.45);
      }
    }

    img {
      position: absolute;
      top: 9px;
      right: 16px;
      width: 14px;
      height: 14px;
    }
  }

  .about {
    height: fit-content;
    min-height: 100%;
    padding-bottom: 24px;

    .about--image {
      overflow: hidden;
      padding-top: 40%;
      position: relative;
      border-radius: 2px;
      background: linear-gradient(359.07deg, rgba(0, 0, 0, 0.66) 0.84%, rgba(0, 0, 0, 0.05) 99.24%);

      img {
        width: 100%;
        border-radius: 2px;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(0, -50%);
      }
    }

    .about--content {
      display: flex;
      margin: 16px auto 0;
      justify-content: space-between;
      max-width: 1120px;

      .about--content--presented {
        display: flex;
        flex-wrap: wrap;

        >* {
          display: flex;
          align-items: center;
          width: fit-content;
          border-radius: 6px;
          padding: 10px;
          margin-right: 8px;
          margin-bottom: 5px;
          cursor: pointer;
          background: white;

          img {
            width: 50px;
            height: 50px;
            margin-right: 11px;
          }
        }
      }

      .about--content--tags {
        display: flex;
        flex-wrap: wrap;

        >* {
          padding: 10px 28px;
          margin-right: 8px;
          border-radius: 6px;
          margin-bottom: 5px;
          background: white;
        }
      }

      .about--content--left {
        width: 60%;
      }

      .about--content--right {
        width: 40%;
        min-width: 350px;
        margin-left: 24px;

        .block {
          background: white;
          border-radius: 2px;
          width: 100%;
          height: fit-content;
        }
      }

      .about--content--call {
        position: relative;

        .about--content--call--live {
          position: absolute;
          top: 24px;
          right: 24px;
          display: flex;
          align-items: center;
          color: #4CD964;

          >*:first-child {
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background: #4CD964;
            margin-right: 6px;
          }
        }

        .about--content--call--start {
          color: rgba(0, 0, 0, 0.85);
          font-size: 1.43em;
          font-weight: bold;
        }

        .about--content--call--duration {
          color: rgba(0, 0, 0, 0.65);
          margin: 8px 0;
        }

        .about--content--call--recurring {
          color: rgba(0, 0, 0, 0.45);
          font-size: 0.86em;
          margin: 10px 0 16px;
        }

        .about--content--call--more {
          color: rgba(0, 0, 0, 0.45);
          font-size: 0.86em;
          margin: 10px 0 0;
          cursor: pointer;
        }

        .about--content--call--join {
          display: block;
          width: fit-content;
          padding: 5px 16px;
          margin: 16px 0 8px;
          background: #E3C36F;
          border-radius: 2px;
          color: white;
        }
      }

      .about--copy-link {

        div {
          color: rgba(0, 0, 0, 0.85);
          width: 100%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        button {
          color: #E3C36F;
          margin-top: 8px;
        }
      }
    }

    .addeventatc {
      padding: 4px 16px;
      border: 1px solid #D9D9D9;
      color: rgba(0, 0, 0, 0.65) !important;
      border-radius: 2px;
      background: white;
      line-height: inherit;
      font-size: inherit;
      font-weight: 500;
      box-shadow: none !important;
      margin: 16px 0 8px 8px;
      z-index: 0 !important;

      .addeventatc_icon {
        display: none;
      }
    }

    .go-to-bubble {
      padding: 4px 16px;
      border: 1px solid #D9D9D9;
      color: rgba(0, 0, 0, 0.65) !important;
      border-radius: 2px;
      background: white;
      line-height: inherit;
      font-size: inherit;
      font-weight: 500;
      box-shadow: none !important;
      margin: 16px 0 8px 8px;
      z-index: 0 !important;

      .addeventatc_icon {
        display: none;
      }
    }

    .submit-button {
      width: 170px;
      font-weight: 700;
    }
  }

  .people {
    padding-bottom: 24px;

    .people--header {
      margin: 24px 0 16px;
      color: rgba(0, 0, 0, 0.45);
    }

    .people--item {
      position: relative;
      width: 100%;
      background: white;
      border: 1px solid #F0F0F0;
      box-sizing: border-box;
      box-shadow: 0px 4px 4px 8px rgba(44, 50, 66, 0.001);
      border-radius: 2px;
      padding-right: 16px;
      margin-top: 16px;

      .person {
        .person--image {
          width: 190px;
          height: 190px;
          margin-right: 16px;

          img {
            width: 190px;
            height: 190px;
          }
        }

        .person--title {
          font-size: 1.14em;
          margin: 24px 0 8px;
        }

        .person--description {
          color: rgba(0, 0, 0, 0.85);
        }

        .person--content {
          color: rgba(0, 0, 0, 0.65);
        }
      }

      .people--actions {
        position: absolute;
        bottom: 16px;
        left: 206px;
        display: flex;

        >* {
          margin-right: 24px;
          img {
            width: 24px;
            height: 24px;
          }
        }
      }

      .people--approval {
        position: absolute;
        top: 16px;
        right: 18px;

        >* {
          padding: 7px 12px;
          border-radius: 6px;
          color: white;
          font-size: 1em;
          line-height: 1.3;

          &:nth-of-type(1) {
            background: #38C116;
          }

          &:nth-of-type(2) {
            background: #F1544A;
            margin-left: 12px;
          }
        }
      }
    }
  }

  .create-webinar {
    padding-bottom: 65px;

    .data-pair {
      margin: 18px 0;

      .form-input,
      .datepicker {
        margin: 0;
      }
    }

    .create-webinar--select {
      position: relative;

      >*:nth-child(1) {
        height: 45px;

        >div:nth-of-type(1) {
          height: 45px;
          padding-left: 13px;

          >*:nth-child(2) {
            display: none;
          }
        }
      }

      >*:nth-child(2) {
        position: absolute;
        right: 22px;
        top: 22px;
        pointer-events: none;
      }

      &.create-webinar--select--error {
        border-color: $red;

        >div:nth-of-type(1) {
          border: 1px solid #F64D28;
          border-radius: 6px;
        }

        .create-webinar--select--message {
          color: $red;
          text-align: right;
          font-size: 1.07em;
        }
      }
    }

    .create-webinar--datetime {
      display: flex;

      >* {
        position: relative;

        >img {
          position: absolute;
          right: 22px;
          top: 22px;
          pointer-events: none;
        }
      }

      >*:nth-child(1) {
        width: 162px;
        min-width: 162px;
        margin-right: 16px;
      }

      >*:nth-child(2) {
        width: 162px;
        min-width: 162px;
        margin-right: 16px;
      }

      >*:nth-child(3) {
        flex-grow: 1;
        max-width: 267px;
        height: 50px;

        >*>* {
          >div:nth-of-type(1) {
            >*:nth-child(1) {
              height: 43px;
              padding: 7px 8px;

              >*:nth-child(1) {
                position: static;
                transform: none;
              }
              >*:nth-child(2) {
                position: absolute;
              }
            }
            >*:nth-child(2) {
              display: none;
            }
          }
        }
      }

      .form-input--message {
        white-space: nowrap;
        text-align: left;
      }

      &.create-webinar--datetime--disabled {
        pointer-events: none;

        input,
        div[class$="-singleValue"] {
          color: #C4C4C4;
        }
      }
    }

    .create-webinar--checkboxes {
      display: flex;
      flex-wrap: wrap;

      >* {
        margin-right: 25px !important;
      }
    }

    .create-webinar--description {
      color: #999999;
    }

    .create-webinar--access {
      display: flex;

      >*:nth-child(1) {
        width: 162px;
        min-width: 162px;
        margin-right: 16px;
      }

      >*:nth-child(2) {
        flex-grow: 1;
        max-width: 267px;
        margin-right: 16px;
      }

      >*:nth-child(3) {
        width: 162px;
        min-width: 162px;
      }
    }

    .create-webinar--recurring {
      display: flex;
      margin-top: 8px;

      >*:nth-child(1) {
        width: 162px;
        min-width: 162px;
        margin-right: 16px;
      }

      >*:nth-child(2) {
        width: 162px;
        min-width: 162px;
        margin-right: 16px;
      }

      >*:nth-child(3) {
        width: 162px;
        min-width: 162px;
      }
    }

    .create-webinar--subblock {
      margin-top: 12px;

      >*:first-child {
        font-size: 1.1em;
        color: #666666;
        margin-bottom: 12px;
      }

      .create-webinar--select {
        width: 340px;
        margin-right: 16px;
      }
    }

    .create-webinar--custom {
      border: 1px solid rgba(0, 0, 0, 0.16);
      border-radius: 2px;
      padding: 16px;
      margin-top: 12px;

      .create-webinar--custom--create {
        border: 1px solid #38C116;
        border-radius: 6px;
        color: #38C116;
        padding: 7px 12px;
      }

      .create-webinar--custom--remove {
        background: #F1544A;
        border: 1px solid #F1544A;
        border-radius: 6px;
        color: white;
        padding: 7px 12px;
      }

      .create-webinar--custom--done {
        background: #38C116;
        border: 1px solid #38C116;
        border-radius: 6px;
        color: white;
        padding: 7px 12px;
        margin-left: 12px;
      }

      >*:not(:first-child) {
        margin-top: 12px;
      }

      .data-pair {
        margin: 0 0 14px;
      }

      input:disabled {
        background: white;
      }
    }

    .create-webinar--advanced {
      cursor: pointer;

      img {
        margin-left: 9px;
      }

      &.create-webinar--advanced--opened {
        img {
          transform: rotate(180deg);
        }
      }
    }

    .create-webinar--duration {
      display: flex;
      margin: 18px 0;

      >* {
        display: flex;
        height: 50px;
        border-radius: 6px;
        overflow: hidden;
        border: 1px solid #C4C4C4;

        .form-input {
          width: fit-content;
          margin: 0;
        }

        input {
          width: 70px;
          border-radius: 0;
          border: none;
          padding: 14px 0 15px 23px;
        }

        .create-webinar--duration--text {
          padding: 15px 23px;
          font-weight: 500;
          font-size: 1.14em;
          line-height: 1.3;
          color: #666666;
          background: #F4F4F4;
          border-left: 1px solid #C4C4C4;;
        }

        &:first-child {
          margin-right: 16px;
        }
      }
    }

    .checkbox {
      margin: 12px 0;
    }
  }

  .q-n-a {
    //height: calc(100vh - 175px);

    .q-n-a--filters {
      width: 100%;
      padding: 0 24px 11px;
      display: flex;
      justify-content: flex-end;
      font-size: 1em;
      line-height: 1.3;
    }

    .q-n-a--actions {
      display: flex;
      padding-left: 52px;
      padding-bottom: 21px;
      border-bottom: 1px solid #D9D9D9;
    }

    .q-n-a--action {
      display: flex;
      align-items: center;
      margin-right: 16px;

      .q-n-a--action--image {
        margin-right: 6px;
        height: 22px;

        img {
          width: 22px;
          height: 22px;
        }
      }
    }

    .q-n-a--action--bordered {
      .q-n-a--action--image {
        background: white;
        border: 1px solid #D9D9D9;
        box-sizing: border-box;
        border-radius: 2px;
        padding: 2px;
        width: 22px;

        img {
          width: 16px;
          height: 16px;
        }
      }
    }
  }

  .webinar-join {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
  }

  .registrants {

    .registrants--header {
      margin: 24px 0 16px;
      color: rgba(0, 0, 0, 0.45);
    }

    .registrants--content {
      min-height: 50vh;
      overflow-x: hidden;
      overflow-y: auto;
      padding-bottom: 64px;
    }

    .dropdown--TO_BE_APPROVED {
      >button {
        text-transform: uppercase;
        padding: 3px 8px;
        border-radius: 2px;
        font-size: 0.86em;
        background: #F0F5FF;
        border: 1px solid #ADC6FF;
        color: #2F54EB;
      }
    }

    .dropdown--APPROVED {
      >button {
        text-transform: uppercase;
        padding: 3px 8px;
        border-radius: 2px;
        font-size: 0.86em;
        background: #F6FFED;
        border: 1px solid #B7EB8F;
        color: #52C41A;
      }
    }

    .dropdown--DENIED {
      >button {
        text-transform: uppercase;
        padding: 3px 8px;
        border-radius: 2px;
        font-size: 0.86em;
        background: #FFF0F6;
        border: 1px solid #FFADD2;
        color: #EB2F96;
      }
    }

    .dropdown--REMOVED {
      >button {
        text-transform: uppercase;
        padding: 3px 8px;
        border-radius: 2px;
        font-size: 0.86em;
        background: #FFF2E8;
        border: 1px solid #FFBB96;
        color: #FA541C;
      }
    }

    .dropdown--BLOCKED {
      >button {
        text-transform: uppercase;
        padding: 3px 8px;
        border-radius: 2px;
        font-size: 0.86em;
        background: #FFF1F0;
        border: 1px solid #FFA39E;
        color: #F5222D;
      }
    }
  }

  .resources {

    .resources--header {
      margin: 24px 0 16px;
      color: rgba(0, 0, 0, 0.45);
    }

    .resources--content {
      overflow-x: hidden;
      overflow-y: auto;
      padding-bottom: 24px;
      min-height: 50vh;
    }

    .resources--link {
      display: flex;
      align-items: center;

      img {
        width: 22px;
        height: 22px;
        margin-right: 16px;
      }
    }

    .resources--tags {
      display: flex;
      flex-wrap: wrap;

      >* {
        background: #FAFAFA;
        border: 1px solid #D9D9D9;
        border-radius: 2px;
        padding: 1px 8px;
        margin: 4px;
      }
    }

    .resources--upload {
      position: relative;
      width: 100%;

      .resources--upload--description {
        color: rgba(0, 0, 0, 0.25);
        margin-left: 16px;
        font-size: 0.86em;
      }

      .dropdown {

        >button {
          border-radius: 2px;
          color: #E3C36F;
          padding: 5px 16px;
          height: 32px;
          white-space: nowrap;
          border: 1px solid #D9D9D9;
        }

        .dropdown--body {
          left: 0;
          right: auto;

          button {
            display: flex;
            align-items: center;
            justify-content: center;

            img {
              width: 17px;
              height: 17px;
            }
          }
        }
      }

      .file-upload {
        display: none;
      }
    }

    .dropdown--body {
      >* {
        text-align: left;

        img {
          margin-right: 8px;
          width: 14px;
          height: 14px;
        }
      }
    }
  }

  .video {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(18, 18, 18, 0.3);
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    .video--close {
      position: absolute;
      top: 20px;
      right: 20px;
      font-size: 3.5em;
      height: 24px;
      line-height: 0.5;
      outline: none;
      cursor: pointer;
      color: white;
    }

    iframe {
      width: 60vw!important;
      height: 60vh !important;
      min-width: 375px !important;
      min-height: 300px !important;
    }
  }

  .to-be-approved {
    display: flex;
    justify-content: center;
    align-items: center;

    .to-be-approved--content {
      width: 100%;
      max-width: 600px;
      padding-bottom: 100px;
    }

    .to-be-approved--title {
      font-weight: bold;
      font-size: 1.57em;
      line-height: 1.3;
      text-align: center;
    }

    .to-be-approved--description {
      font-weight: 600;
      font-size: 1.21em;
      line-height: 1.3;
      text-align: center;
      margin-top: 16px;
    }
  }

  .join-registration {
    display: flex;
    justify-content: center;

    .join-registration--content {
      width: 100%;
      max-width: 600px;
      padding-bottom: 100px;
    }

    .join-registration--title {
      font-weight: bold;
      font-size: 1.57em;
      line-height: 1.3;
      text-align: center;
    }

    .join-registration--description {
      font-weight: 600;
      font-size: 1.21em;
      line-height: 1.3;
      text-align: center;
      margin-top: 16px;
    }

    .join-registration--questions {
      width: 100%;
      max-width: 540px;
      border: 1px solid rgba(0, 0, 0, 0.16);
      border-radius: 2px;
      padding: 24px 16px;
      margin: 20px auto 32px;

      >*:not(:first-child) {
        margin-top: 18px;
      }
    }

    .submit-button {
      max-width: 508px;
      margin: 0 auto 64px;
    }
  }

  .default {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    .default--content {
      img {
        display: block;
        width: 320px;
        height: 220px;
        margin: 0 auto;
      }
    }

    .default--title {
      font-weight: bold;
      font-size: 1.71em;
      line-height: 1.3;
      text-align: center;
      margin: 16px 0;
    }

    .default--body {
      display: flex;
    }

    .suffix-input {

      .form-input {
        width: 110px;
      }

      .suffix-input--status {
        bottom: -17px;
      }
    }

    .default--error {
      color: #F64D28;
      font-size: 1.07em;
    }

    .submit-button {
      margin-left: 12px;
      padding: 13px 23px;
    }

    .default--footer {
      margin-top: 12px;
      font-size: 1em;
      line-height: 1.3;
      color: #666666;
    }
  }

  .empty-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 50vh;
    text-align: center;

    .empty-page--title {
      font-size: 1.71em;
      color: rgba(0, 0, 0, 0.85);
      margin-top: 30px;
    }

    .empty-page--description {
      margin: 16px 0 24px;
      color: rgba(0, 0, 0, 0.45);
      max-width: 400px;
    }

    .action-button {
      padding: 13px 34px;
      height: auto;
    }
  }

  .grid-view {
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    padding-bottom: 24px;

    .grid-view--header {
      font-size: 1.71em;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.85);
      background: #FFFFFF;
      padding: 17px 24px;
    }

    .grid-view--subheader {
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding: 24px;
    }

    .grid-view--item {
      width: 310px;
      margin: 12px;
      background: white;
      box-shadow: 0px 4px 4px 8px rgba(44, 50, 66, 0.001);
      border-radius: 2px;

      .grid-view--item--image {
        overflow: hidden;
        padding-top: 40%;
        position: relative;
        border-radius: 2px;
        background: linear-gradient(359.07deg, rgba(0, 0, 0, 0.66) 0.84%, rgba(0, 0, 0, 0.05) 99.24%);

        img {
          width: 100%;
          border-radius: 2px;
          position: absolute;
          top: 50%;
          left: 0;
          transform: translate(0, -50%);
        }
      }

      .grid-view--item--content {
        padding: 16px;
      }

      .grid-view--item--title {
        font-size: 1.14em;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.85);
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .grid-view--item--subtitle {
        margin-top: 8px;
        color: rgba(0, 0, 0, 0.85);
        font-size: 0.86em;
      }

      .grid-view--item--separator {
        width: 100%;
        height: 1px;
        background: #F0F0F0;
        margin: 8px 0;
      }

      .grid-view--item--date {
        display: flex;
        color: rgba(0, 0, 0, 0.65);

        img {
          margin-right: 8px;
        }
      }

      .grid-view--item--actions {
        display: flex;
        margin-top: 4px;

        button {
          color: #E3C36F;
        }
      }
    }

    .navigation-group {
      padding-left: 8px;

      .navigation-group--content {
        display: flex;
        flex-wrap: wrap;
        padding-left: 6px;
        padding-right: 12px;
        margin-top: -6px;
      }
    }
  }
}

@media (max-width: $screen-width-md) {
  .page-webinars {
    .q-n-a {
      height: calc(100vh - 236px);
    }

    .to-be-approved {

      .to-be-approved--content {
        padding-bottom: 0;
      }
    }

    .join-registration {

      .join-registration--content {
        padding-bottom: 0;
      }
    }

    .default {
      display: none;
    }

    .registrants {
      .registrants--content {
        overflow-x: scroll;
      }
    }
  }
}

.call {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: white;
  z-index: 1;

  .call--back {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 1;
  }

  .call--header {
    width: 100%;
    font-size: 1.33em;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
    background: white;
    border-bottom: 1px solid #F0F0F0;
    padding: 20px 24px;
  }

  .call--content {
    display: flex;

    iframe {
      width: 100%;
      height: 100vh;
    }

    .call--left {
      padding: 24px;
      background: #F7F8F9;
      flex-grow: 1;

      >* {
        margin: auto;
        width: fit-content;
      }

      .call--left--image {
        img {
          width: 100%;
          max-width: 912px;
        }
      }

      .call--left--presented {
        display: flex;
        flex-wrap: wrap;

        >* {
          display: flex;
          align-items: center;
          width: fit-content;
          border-radius: 6px;
          padding: 10px;
          margin-right: 8px;
          margin-bottom: 5px;
          cursor: pointer;
          background: white;

          img {
            width: 50px;
            height: 50px;
            margin-right: 11px;
          }
        }
      }
    }

    .call--right {
      min-width: 360px;
      width: 100%;
      max-width: 400px;

      .messaging {
        height: calc(100vh - 126px);
      }
    }
  }
}

@media (max-width: $screen-width-xs) {

  .page-webinars {
    .about {
      .about--header {
        flex-wrap: wrap;

        .about--header-0,
        .about--header-1,
        .about--header-2 {
          width: 100%;
          align-items: flex-start;
        }

        .dropdown {
          text-align: left;
        }
      }

      .about--content {
        flex-wrap: wrap;
        height: auto;

        .about--content--description {
          margin-right: 0;
          margin-bottom: 14px;
          min-height: auto;
        }

        .about--content--left,
        .about--content--right {
          width: 100%;
          min-width: auto;
          margin-left: 0;
        }
      }
    }

    .resources {
      table {
        td:nth-of-type(2),
        td:nth-of-type(3),
        td:nth-of-type(4) {
          display: none;
        }
      }
    }

    .people {

      .people--item {
        .person {
          flex-direction: column;
          align-items: center;
          text-align: center;

          .person--image {
            width: 64px;
            height: 64px;
            margin: 16px 0 12px;

            img {
              width: 64px;
              height: 64px;
            }
          }

          .person--title {
            display: block;
            margin: 0;
          }

          .person--description {
            margin: 8px 0 6px;
          }

          .person--content {
            margin: 0;
          }
        }

        .people--actions {
          position: static;
          display: flex;
          justify-content: center;
          margin: 16px;
        }
      }
    }

    .create-webinar {
      .create-webinar--access,
      .create-webinar--datetime {
        flex-direction: column;
        >*:not(:first-child) {
          margin-top: 10px;
        }
      }

      .suffix-input {
        flex-wrap: wrap;

        >*:nth-child(1) {
          border-right: 1px solid #C4C4C4 !important;
          margin-bottom: 10px;
        }
      }
    }
  }
}