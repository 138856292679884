.page-messages {

  .button--search {
    margin-top: 6px;
    margin-right: 16px;

    img {
      width: 24px;
      height: 24px;
    }
  }

  .button--filter {

    img {
      width: 22px;
      height: 24px;
    }
  }

  .person--title {
    width: 165px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .person--description {
    width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    height: 19px;
  }

  .navigation-item {
    .navigation-item--action {
      top: 16px;

      >* {
        position: relative;

        >*:nth-child(1) {
          position: absolute;
          top: 0;
          right: 0;
          color: rgba(0, 0, 0, 0.45);
          white-space: nowrap;
        }

        >*:nth-child(2) {
          position: absolute;
          top: 28px;
          right: 0;
          background: $yellow-dark;
          width: 10px;
          height: 10px;
          border-radius: 50%;
        }
      }
    }
  }

  .notfound-page {
    width: 100%;
    height: 100vh;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;

    .notfound-page--content {
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
    }

    img {
      width: 120px;
      height: 120px;
    }

    .notfound-page--title {
      font-style: normal;
      font-weight: 500;
      font-size: 48px;
      line-height: 56px;
      text-align: center;
      color: rgba(0, 0, 0, 0.85);
      margin-top: 16px;
    }

    .notfound-page--subtitle {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: rgba(0, 0, 0, 0.45);
      margin-top: 16px;
    }
  }
}