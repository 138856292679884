.messaging {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  .messaging--messages {
    overflow-x: hidden;
    overflow-y: auto;
    height: 100%;
  }

  .messaging--item {
    position: relative;
    padding: 8px 24px;

    .messaging--item--content {
      display: flex;
    }

    .messaging--item--image {
      margin-right: 12px;
      height: 40px;
      cursor: pointer;

      img {
        width: 40px;
        height: 40px;
        border-radius: 2px;
      }
    }

    .messaging--item--header {
      display: flex;
      color: $gray;
      white-space: nowrap;

      >* {
        margin-right: 8px;
      }
    }

    .messaging--item--title {
      font-weight: 500;
      color: rgba(0, 0, 0, 0.85);
      max-width: 39vw;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      cursor: pointer;
    }

    .messaging--item--date {
      margin-top: 4px;
      color: $gray
    }

    .messaging--item--message {
      color: rgba(0, 0, 0, 0.65);
    }

    .messaging--item--actions {
      margin-top: 12px;
    }

    .messaging--item--hover {
      position: absolute;
      top: 16px;
      right: 18px;
      display: none;

      img {
        width: 20px;
        height: 20px;
      }
    }

    &:hover {
      background: $gray-hover;

      .messaging--item--hover {
        display: block;
      }
    }
  }

  .messaging--today {
    position: relative;
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
    color: rgba(0, 0, 0, 0.65);

    &:before {
      content: ' ';
      position: absolute;
      left: 0;
      right: calc(50% + 35px);
      top: 10px;
      border-top: 1px solid #F0F0F0;
    }

    &:after {
      content: ' ';
      position: absolute;
      right: 0;
      width: calc(50% - 35px);
      top: 10px;
      border-top: 1px solid #F0F0F0;
    }
  }

  .messaging--chat {
    display: flex;
    padding: 16px 24px;
  }

  .messaging--chat--content {
    position: relative;
    width: 100%;
    min-height: 48px;
    background: #FAFAFA;
    border: 1px solid #D9D9D9;
    box-sizing: border-box;
    border-radius: 2px;
  }

  .messaging--chat--input {
    >* {
      max-height: 30vh;
      min-height: 48px;
      overflow-x: hidden;
      overflow-y: auto;
      width: 100%;
      border: none;
      padding: 8px 56px 8px 16px;
      background: #FAFAFA;
      border-radius: 2px;
      box-sizing: border-box;
      outline: none;

      &:focus {
        border: 1px solid $black-focus;
      }
    }
  }

  .messaging--chat--placeholder {
    position: absolute;
    top: 50%;
    left: 16px;
    transform: translate(0, -50%);
    color: rgba(0, 0, 0, 0.45);
    pointer-events: none;
  }

  .messaging--chat--emoji {
    position: absolute;
    right: 8px;
    bottom: 8px;
    width: 24px;
    height: 24px;

    img {
      width: 24px;
      height: 24px;
    }
  }

  .messaging--send {
    background: #E3C36F;
    border-radius: 2px;
    color: white;
    font-weight: 500;
    padding: 14px 16px;
    margin-left: 8px;
  }
}