.forum-page {

  .page-content .page-content--body > * {
    max-width: none;
  }

  .messages {
    display: flex;
    flex-direction: column;
  }

  .message {
    position: relative;
    display: flex;
    padding: 24px;
    width: 100%;
    max-width: 644px;
    height: fit-content;
    border-radius: 2px;
    margin-top: 24px;
    background: white;

    .message--like {
      display: flex;
      align-items: center;
      flex-direction: column;
      height: fit-content;
      color: #999999;
      margin-right: 8px;

      img {
        width: 20px;
        height: 20px;
        transform: rotate(180deg);
      }
    }

    .message--body {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
    }

    .message--info {
      display: flex;
      color: rgba(0, 0, 0, 0.65);

      .message--info--separator {
        margin: 0 4px;
      }
    }

    .message--answers {
      margin-left: 16px;
      display: flex;
      color: #999999;
    }

    .message--hover {
      position: absolute;
      top: 10px;
      right: 0;
      display: none;
    }

    &:hover {
      .message--hover {
        display: block;
      }
    }
  }

  .announcement-dialog {
    .announcement-dialog--placeholder {
      color: rgba(0, 0, 0, 0.45);
    }

    .announcement-dialog--input {
      margin-top: 8px;
      height: 174px;
      border: 1px solid #D9D9D9;
      border-radius: 2px;
      padding: 5px;

      >* {
        width: 100%;
        height: 174px;
      }
    }
  }
}