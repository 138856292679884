$screen-width-xs: 568px;
$screen-width-sm: 768px;
$screen-width-md: 992px;
$screen-width-lg: 1200px;
$screen-width-xl: 1680px;
$screen-width-4k: 1920px;

$black: #000000;
$black-focus: #121212;
$black-light: #2A2A2A;
$gray: #999999;
$gray-light: #C4C4C4;
$gray-lightest: #F4F4F4;
$gray-hover: rgba(244, 244, 244, 0.5);
$white: #ffffff;
$yellow-dark: #E3C36F;
$yellow-light: rgba(227, 195, 111, 0.5);
$blue: #1074AF;
$blue-light: #28ACF6;
$brown: #906C0D;
$brown-light: rgba(144, 108, 13, 0.5);
$red: #F64D28;

@font-face {
  font-family: 'Roboto';
  src: 	local('Roboto-Regular.ttf')
  url('./Roboto-Regular.ttf')
  format('truetype');
}

html {
  overflow-x: hidden;
  overflow-y: auto;
}

body {
  overflow-y: auto;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  line-height: 1.572;
}
a,
button,
input,
textarea,
div {
  outline: none;
  box-sizing: border-box;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
}

a {
  color: $blue-light;
  text-decoration: none;
  cursor: pointer;
}

button {
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
  font-size: 14px;
  line-height: 1.572;
}

#zmmtg-root {
  display: none;
  z-index: 1;
}

.dropdown-menu {
  right: 0 !important;
}

.dropdown-toggle {
  background: transparent !important;
  border: none !important;
  color: white !important;
}

.meeting-info-container--left-side {
  display: none !important;
}

.flex {
  display: flex;
}

.flex-between {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.flex-center {
  display: flex;
  align-items: center;
}

.separator {
  width: 100%;
  height: 1px;
  background: rgba(0, 0, 0, 0.1);
}

.toast--confirmation {
  position: fixed;
  top: 30px;
  right: 30px;
  background: #38C116;
  border: 1px solid #38C116;
  box-sizing: border-box;
  border-radius: 6px;
  color: $white;
  padding: 7px 16px;
  z-index: 1000;
}

.toast--error {
  position: fixed;
  top: 30px;
  right: 30px;
  background: #F1544A;
  border: 1px solid #F1544A;
  box-sizing: border-box;
  border-radius: 6px;
  color: $white;
  padding: 7px 16px;
  z-index: 1000;
}

.capitalize {
  text-transform: capitalize;
}

.red {
  color: #FF4D4F;
}

.actions-dropdown {
  >button {
    background: #FFFFFF;
    border: 1px solid #D9D9D9;
    padding: 1px 9px 9px;
    height: 32px;
  }
}

table {
  background: white;
  width: 100%;

  thead {
    color: rgba(0, 0, 0, 0.85);
  }

  tbody {
    color: rgba(0, 0, 0, 0.65);
  }

  tr {
    box-shadow: inset 0 -1px 0 #F0F0F0;
  }

  td {
    padding: 16px 16px 17px;
  }
}

.tables-empty {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  .tables-empty--content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .tables-empty--image {
    width: 120px;
  }

  .tables-empty--title {
    font-weight: 500;
    font-size: 48px;
    line-height: 56px;
    margin: 16px 0;
    text-align: center;
  }

  .tables-empty--subtitle {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.45);
    text-align: center;
  }
}

@import 'components/content/styles';
@import 'components/form/styles';
@import 'components/loader/styles';
@import 'components/messaging/styles';
@import 'components/modal/styles';
@import 'components/navigation/styles';
@import 'components/page/styles';
@import 'components/profile/styles';
@import 'components/tabs/styles';
@import 'pages/account/styles';
@import 'pages/linkedin/styles';
@import 'pages/contacts/styles';
@import 'pages/messages/styles';
@import 'pages/join/styles';
@import 'pages/landing/styles';
@import 'pages/onboarding/styles';
@import 'pages/webinars/styles';
@import 'pages/reception/styles';
@import 'pages/apply/styles';
@import 'pages/announce/styles';
@import 'pages/forum/styles';
@import 'pages/table/styles';
@import 'pages/notfound/styles';
@import 'projects/ithra/index';
