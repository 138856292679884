.page {
  position: relative;
  display: flex;
  height: 100vh;
  width: 100%;
  padding-left: 80px;
  background: #f7f8f9;
}

.page-navigation {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  width: 80px;
  background: black;
  z-index: 1;

  .more-navigation {
    display: none;
  }

  .page-navigation--logo {
    height: 80px;
    width: 80px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 64px;
    }
  }

  .page-navigation--item {
    position: relative;
    height: 68px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
      width: 24px;
      height: 24px;

      &:nth-of-type(2) {
        display: none;
      }
    }

    .badge {
      position: absolute;
      top: 5px;
      right: 5px;
    }

    .page-navigation--item--text {
      font-size: 0.85em;
      color: white;
      margin-top: 4px;
    }

    &.page-navigation--avatar {
      >div {
        max-height: 24px;
      }

      img {
        border-radius: 4px;
      }
    }
  }

  .page-navigation--item--active {
    position: relative;
    background: #3AFED1;

    >img {
      &:nth-of-type(1) {
        display: none;
      }
      &:nth-of-type(2) {
        display: block;
        margin-bottom: 2px;
      }
    }

    div {
      color: $yellow-dark;
    }
  }
}

.page-content {
  width: 100%;
  position: relative;

  .page-content--title {
    width: 100%;
    padding: 19px 24px;
    font-weight: 500;
    background: white;
    display: flex;
    border-bottom: 1px solid #F0F0F0;

    button {
      margin: 0 25px 0 0;

      img {
        width: 8px;
        height: 20px;
      }
    }

    >div {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: calc(100vw - 550px);
      font-size: 1.14em;
    }
  }

  .page-content--title--no-back {
    >button {
      display: none;
    }
  }

  .page-content--subtitle {
    border-bottom: 1px solid #F0F0F0;
  }

  .page-content--action {
    position: absolute;
    top: 16px;
    right: 24px;

    button {
      margin: 0;
    }
  }

  .page-content--actions {
    position: absolute;
    top: 16px;
    right: 22px;

    button {
      margin: 0;
    }

    img {
      display: block;
      width: 11px;
      height: 2px;
    }

    .dropdown {
      >button {
        width: 32px;
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: white;
        border: 1px solid #D9D9D9;
        border-radius: 2px;
      }
    }
  }

  .page-content--body {
    width: 100%;
    height: calc(100vh - 66px);
    padding: 0 24px;
    display: flex;
    justify-content: center;
    background: #f7f8f9;
    overflow-x: hidden;
    overflow-y: auto;

    >* {
      width: 100%;
      max-width: 600px;
    }
  }

  .page-content--with-subtitle {
    height: calc(100vh - 122px);
    padding-top: 24px;
  }

  .page-content--white-body {
    background: white;
  }

  .page-content--no-padding {
    padding: 0;
  }

  .page-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }

  &.page-content--full-width {

    >.page-content--body {

      >* {
        max-width: none;
      }
    }
  }
}

@media (max-width: $screen-width-md) {
  .page {
    padding-left: 0;
    padding-bottom: 60px;

    .page-content {
      padding: 0;
    }
  }

  .page-navigation {
    position: fixed;
    top: auto;
    width: 100%;
    height: 60px;
    display: flex;
    border-right: 0;

    >a:nth-child(3),
    >a:nth-child(4),
    >a:nth-child(6),
    >a:nth-child(9),
    >a:nth-child(10),
    >a:nth-child(11),
    >a:nth-child(12) {
      display: none;
    }

    .more-navigation {
      display: block;
      position: relative;
      width: 100%;

      .more-navigation--body {
        position: absolute;
        top: -65px;
        right: 0;
        display: flex;
        width: 100vw;
        background: black;
        height: 65px;
        overflow: hidden;
      }
    }

    .page-navigation--logo {
      display: none;
    }

    a, .page-navigation--item {
      width: 100%;
      padding-bottom: 5px;
    }

    .page-navigation--item--active {
      &:before {
        top: auto;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 6px;
        background: $yellow-dark;
        border-radius: 20px 20px 0 0;
      }
    }
  }

  .page-content {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 74px;

    .page-content--title {
      padding: 16px 18px;

      button {
        margin-right: 15px;
      }

      div {
        max-width: 100%;
      }
    }

    .page-content--title--no-back {
      >button {
        display: block;
      }
    }

    .page-content--action {
      right: 8px;
    }

    .page-content--actions {
      right: 10px;
    }

    .page-content--body {
      margin: 0;
      height: calc(100vh - 116px);
    }

    .page-content--with-subtitle {
      height: calc(100vh - 176px);
    }
  }
}