.profile-details {
  .profile-details--discussion {
    position: relative;
  }

  .profile-details--call {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 8px 18px;
    display: flex;
    justify-content: space-between;
    background: $yellow-dark;
    z-index: 1;

    .profile-details--call--description {
      display: flex;
      align-items: center;
      font-size: 1.14em;
      line-height: 1.3;
      color: $white;
      margin-right: 18px;

      img {
        width: 24px;
        height: 24px;
        min-width: 24px;
        margin-right: 12px;
      }
    }

    button {
      font-size: 1.14em;
      line-height: 1.3;
      color: $white;
      white-space: nowrap;
    }
  }
}

.profile-details--unauthorized {

  .page-content--title {
    button {
      pointer-events: none;
    }
  }

  .page-content--body {
    >* {
      pointer-events: none;
    }
  }
}

.account-profile {

  .block {

    .block--title {
      padding: 22px 18px 12px;
    }

    .block--content {
      padding: 0 0 22px;
    }

    .block--content--gray {
      padding-left: 18px;
      padding-right: 18px;
    }

    .person {
      padding: 10px 18px 6px;
      color: $black;

      .person--title {
        margin-top: 9px;
      }

      &:hover {
        background: rgba(244, 244, 244, 0.5);
      }
    }
  }

  .profile-main {
    padding: 22px 18px;
    text-align: center;

    .profile-picture {
      img {
        width: 90px;
        height: 90px;
        border-radius: 2px;
      }
    }

    .profile-name {
      display: flex;
      justify-content: center;
      font-weight: bold;
      font-size: 2.57em;
      line-height: 1.3;

      .profile-name--action {
        margin-left: 10px;

        img {
          width: 24px;
          height: 24px;
        }
      }
    }

    .profile-actions {
      display: flex;
      justify-content: center;
      margin: 16px auto;

      >* {
        padding: 5px 16px;
        height: 30px;
        margin-bottom: 0;
        font-size: 1em;
        width: fit-content;
      }

      .edit-button {
        display: flex;
        align-items: center;
        border: 1px solid #999999;
        box-sizing: border-box;
        border-radius: 2px;
        height: 30px;
        color: #999999;
        margin-right: 12px;

        img {
          width: 16px;
          height: 16px;
          margin-right: 8px;
        }
      }
    }

    .profile-location {
      display: flex;
      justify-content: center;
      font-weight: 500;
      font-size: 1.14em;
      line-height: 1.3;
      color: $gray;
      margin-top: 8px;
      margin-bottom: 8px;

      img {
        width: 19px;
        height: 19px;
        margin-right: 8px;
      }
    }

    .action-button {
      margin: 16px auto 0;
    }

    .bordered-button {
      margin: 0 auto;
    }
  }

  .profile-description {
    display: flex;
    justify-content: space-between;
    font-size: 1.14em;
    line-height: 1.3;
    padding: 22px 18px;

    .profile-description--title {
      font-weight: bold;
      font-size: 1.286em;
    }

    img {
      width: 44px;
      height: 44px;
    }
  }

  .person .person--image img {
    border: none;
  }
}