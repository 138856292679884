.page-contacts {

  .people--search {
    position: relative;

    input {
      padding: 6px 16px;
      min-height: auto;
      background: #FAFAFA;
      border: 1px solid #F0F0F0;
      box-sizing: border-box;
      border-radius: 2px;

      &::placeholder {
        color: rgba(0, 0, 0, 0.45);
      }
    }

    img {
      position: absolute;
      top: 9px;
      right: 16px;
      width: 14px;
      height: 14px;
    }
  }

  .button--search {
    margin-top: 6px;
    margin-right: 16px;

    img {
      width: 24px;
      height: 24px;
    }
  }

  .button--filter {

    img {
      width: 22px;
      height: 24px;
    }

    .dropdown--body {
      z-index: 1;

      >* {
        font-size: 0.7em;
      }
    }
  }

  .person--title {
    width: 210px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .person--description {
    width: 210px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}