.block {
  .block--title {
    padding: 24px 24px 16px;
    font-weight: 500;
    font-size: 1.14em;
  }

  .block--content {
    padding: 0 24px 24px;
    color: rgba(0, 0, 0, 0.65);
  }

  .block--content--gray {
    color: #666666;
  }

  &.block--no-padding {
    >* {
      padding-left: 0;
    }
  }
}

.person {
  display: flex;

  .person--image {
    margin-right: 12px;
    cursor: pointer;
    height: 48px;

    img {
      width: 48px;
      height: 48px;
      border: 1px solid #F0F0F0;
      border-radius: 2px;
    }
  }

  .person--image--not-rounded {
    img {
      border-radius: inherit;
    }
  }

  .person--title {
    display: flex;
    align-items: center;
    cursor: pointer;

    >div:first-child {
      font-weight: 500;
      color: rgba(0, 0, 0, 0.85);
    }
  }

  .person--title--subtitle {
    color: #999999;
    margin-left: 8px;
  }

  .person--description {
    font-weight: normal;
    color: rgba(0, 0, 0, 0.65);
    margin-top: 2px;
  }

  .person--content {
    font-weight: normal;
    margin-top: 8px;
  }

  .person--description--black {
    color: $black;
  }

  &.person--smaller {

    .person--title,
    .person--description {
      font-size: 1em;
      line-height: 1.3;
    }

    .person--image {
      img {
        width: 38px;
        height: 38px;
      }
    }
  }
}

.data-pair {
  .data-pair--title {
    font-weight: bold;
    font-size: 1.21em;
    line-height: 1.3;
    margin-bottom: 6px;
  }

  .data-pair--content {
  }
}

.dropdown {
  position: relative;

  .dropdown--body {
    position: absolute;
    top: 25px;
    right: 0;
    width: 187px;
    background: #FFFFFF;
    box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
    border-radius: 2px;
    z-index: 1;
    padding: 4px;

    >* {
      width: 100%;
      display: block;
      padding: 8px;
      text-align: center;
    }
  }
}

.badge {
  width: 21px;
  min-width: 21px;
  height: 19px;
  background: #E4C87F !important;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px 4px !important;

  >div {
    color: black !important;
    font-size: 11px !important;
    font-weight: bold !important;
  }
}