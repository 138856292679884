.page-landing {
  position: relative;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  background: $black;
  overflow-x: hidden;
  overflow-y: auto;

  .landing--content {
    width: 100%;
    max-width: 450px;
    padding: 72px 24px;
    border-radius: 6px;
    text-align: center;
    z-index: 1;

    .landing--logo {
      img {
        width: 120px;
      }
    }

    .landing--title {
      font-weight: 600;
      font-size: 36px;
      line-height: 44px;
      color: white;
      margin-top: 32px;
    }

    .landing--title-smaller {
      margin-top: 32px;
      font-size: 1.7em;
      font-weight: bold;
      color: white;
    }

    .landing--subtitle {
      color: white;
      font-size: 1.14em;
      margin: 16px 0 24px;
    }

    .input-pair {
      margin: 32px 0 24px;

      .input-pair--label {
        color: white;
        font-size: 0.86em;
      }

      input {
        height: 48px;
      }
    }

    .landing--separator {
      width: 100%;
      height: 1px;
      background: #999999;
      margin-top: 24px;
    }

    .landing--linkedin {
      height: 48px;
      margin-top: 24px;
      width: 100%;
      background: #0077B7;
      border-radius: 2px;
      padding: 13px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;

      img {
        width: 16px;
        height: 16px;
        margin-right: 8px;
      }
    }

    .landing--zoom {
      height: 48px;
      margin-top: 24px;
      width: 100%;
      background: #2D8CFF;
      border-radius: 2px;
      padding: 13px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;

      img {
        width: 46px;
        height: 24px;
      }
    }

    .landing--footer {
      font-weight: 500;
      font-size: 0.86em;
      color: #999999;
      margin-top: 32px;

      a {
        color: #999999;
        text-decoration: underline;
      }
    }
  }
}

.root {

  .landing--content {

    .landing--title {
      line-height: 1.2;
    }

    .landing--subtitle {
      color: rgba(0, 0, 0, 0.85) !important;
      margin-top: 24px;
    }
  }
}