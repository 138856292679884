.reception {
  display: block;
  overflow-x: hidden;
  overflow-y: auto;

  .page-1 {
    position: relative;
    height: 100vh;

    img {
      width: 100%;
      height: 100vh;
    }

    video {
      width: 100%;
      max-width: 853.33px;
    }

    .page-1--content {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .page-1--content--title {
      color: white;
      font-weight: 500;
      font-size: 48px;
      line-height: 56px;
      margin: 48px 0 104px;
      padding: 0 10px;
    }
  }

  .page-2 {
    background: black;

    .page-2--content {
      width: 100%;
      max-width: 1040px;
      padding: 74px 0;
      margin: 0 auto;

      .page-2--content--title {
        font-weight: bold;
        font-size: 36px;
        line-height: 48px;
        color: #3AFED1;
        text-align: center;
        width: 100%;
      }

      .page-2--content--blocks {
        margin-top: 52px;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
      }

      .page-2--content--block {
        width: 320px;
        background: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 12px;

        img {
          width: 48px;
          height: 48px;
          border: 1px solid #F0F0F0;
          box-sizing: border-box;
          border-radius: 2px;
          margin-top: 16px;
        }

        .page-2--content--block--title {
          margin-top: 8px;
          font-weight: 500;
          font-size: 14px;
          line-height: 22px;
          color: black;
        }

        .page-2--content--block--content {
          margin-top: 2px;
          font-size: 14px;
          line-height: 22px;
          color: rgba(0, 0, 0, 0.65);
          margin-bottom: 16px;
        }
      }
    }
  }

  .reception--page-3 {
    display: flex;
    justify-content: center;
    background: black;

    >* {
      max-width: 1100px;
      width: 100%;
      padding: 24px;
    }

    .reception--page-3--title {
      font-weight: bold;
      font-size: 36px;
      line-height: 48px;
      margin: 144px auto 112px;
      color: #3AFED1;
      text-align: center;
    }

    .reception--page-3--tabs {
      display: flex;
      background: white;
      padding: 5px;
      margin-bottom: 37px;
      border-radius: 2px;
      cursor: pointer;

      >* {
        padding: 13px;
        flex-grow: 1;
        text-align: center;
        color: black;
        font-weight: 500;
      }

      .reception--page-3--tabs--active {
        background: #3AFED1;
        border-radius: 2px;
        color: black;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
      }
    }

    .reception--page-3--item {
      background: white;
      padding: 24px;
      color: rgba(0, 0, 0, 0.85);
      margin-top: 24px;
      border-radius: 2px;

      .reception--page-3--item--title {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
      }

      .reception--page-3--item--time {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
      }

      .reception--page-3--item--type {
        font-size: 14px;
        line-height: 22px;
        margin-top: 16px;
      }

      .reception--page-3--item--actions {
        display: flex;
        margin-top: 8px;
        height: 22px;

        .addeventatc {
          display: block;
          margin-left: 16px;
          padding: 0;
          border: none;
          box-shadow: none !important;
          background: none;
          font-weight: normal;
          font-size: 14px;
          line-height: 22px;
          color: rgba(0, 0, 0, 0.45) !important;
          font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;

          &:hover {
            background: white;
          }

          .addeventatc_icon {
            display: none;
          }
        }
      }
    }
  }

  @media (max-width: $screen-width-xs) {
    .flex-between {
      flex-direction: column;
    }

    .reception--page-1--title {
      font-size: 30px !important;
      line-height: 38px !important;
    }

    .reception--page-1--scroll {
      bottom: 65px !important;
    }

    .reception--page-3--item--time {
      margin-top: 16px;
    }
  }
}
