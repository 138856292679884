.loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100001;
  display: none;
  align-items: center;
  justify-content: center;
  background: $black;
  opacity: 0.5;
  overflow: hidden;

  .loader-square {
    display: inline-block;
    width: 30px;
    height: 30px;
    border: 4px solid $gray-lightest;
    top: 50%;
    animation: loader-square 4s infinite ease;
    position: absolute;
  }

  .loader-square-inner {
    vertical-align: top;
    display: inline-block;
    width: 100%;
    background-color: $gray-lightest;
    animation: loader-square-inner 4s infinite ease-in;
  }

  &.opened {
    display: flex;
  }

  @keyframes loader-square {
    0% {
      transform: rotate(0deg);
    }

    25% {
      transform: rotate(180deg);
    }

    50% {
      transform: rotate(180deg);
    }

    75% {
      transform: rotate(360deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes loader-square-inner {
    0% {
      height: 0;
    }

    25% {
      height: 0;
    }

    50% {
      height: 100%;
    }

    75% {
      height: 100%;
    }

    100% {
      height: 0;
    }
  }
}
