.notfound-page {
  width: 100%;
  height: 100vh;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;

  .notfound-page--content {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  img {
    width: 120px;
    height: 120px;
  }

  .notfound-page--title {
    font-style: normal;
    font-weight: 500;
    font-size: 48px;
    line-height: 56px;
    text-align: center;
    color: rgba(0, 0, 0, 0.85);
    margin-top: 16px;
  }

  .notfound-page--subtitle {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: rgba(0, 0, 0, 0.45);
    margin-top: 16px;
  }
}