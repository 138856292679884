.app-ithra {
  .action-button,
  .submit-button,
  .page-webinars .about .about--content .about--content--call .about--content--call--join,
  .messaging .messaging--send,
  .logo-upload--button button {
    background: #3AFED1;
    color: black;
    font-weight: 500;
  }

  .page-navigation .page-navigation--item--active .page-navigation--item--text {
    color: black;
  }

  .tabs .tabs--item--active {
    border-bottom: 3px solid #4A525C;
  }

  .page-landing {
    align-items: center;

    .landing--content {

      .landing--separator,
      .landing--linkedin {
        display: none;
      }
    }
  }

  .onboarding {
    align-items: inherit;

    .landing--content {
      margin-top: 5%;
      margin-bottom: 64px;
    }
  }

  .join {
    .join--content--right {
      .join--separator,
      .join--linkedin {
        display: none;
      }
    }
  }

  .navigation-item--title--action >*,
  .page-webinars .about .about--content .about--copy-link button {
    color: #4A525C;
  }

  @media (max-width: $screen-width-xs) {
    .onboarding {

      .landing--content {
        margin-top: 0;
      }
    }
  }
}