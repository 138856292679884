.announcements-page {

  .messages {
    display: flex;
    flex-direction: column;
  }

  .message {
    position: relative;
    padding: 24px;
    width: 100%;
    max-width: 644px;
    height: fit-content;
    background: white;
    border-radius: 2px;
    margin-top: 24px;

    .message--header {
      display: flex;
      justify-content: space-between;

      .message--person {
        display: flex;

        .message--person--image {
          height: 40px;
          margin-right: 12px;

          img {
            width: 40px;
            height: 40px;
          }
        }
      }

      .message--like {
        display: flex;
        align-items: flex-start;
        height: fit-content;

        div {
          margin-right: 4px;
        }

        img {
          width: 24px;
          height: 24px;
        }
      }
    }

    .message--body {
      font-weight: 500;
      font-size: 24px;
      line-height: 30px;
      margin-top: 16px;
    }

    .message--hover {
      position: absolute;
      top: 10px;
      right: 0;
      display: none;
    }

    &:hover {
      .message--hover {
        display: block;
      }
    }
  }

  .announcement-dialog {
    .announcement-dialog--placeholder {
      color: rgba(0, 0, 0, 0.45);
    }

    .announcement-dialog--input {
      margin-top: 8px;
      height: 174px;
      border: 1px solid #D9D9D9;
      border-radius: 2px;
      padding: 5px;

      >* {
        width: 100%;
        height: 174px;
      }
    }
  }
}