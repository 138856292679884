.creative-solutions {
  .creative-solutions--content {
    width: 100%;
    margin: 0 auto;
  }

  .page-content--body > * {
    max-width: none;
  }

  .creative-solutions--header {
    position: absolute;
    top: 65px;
    left: 0;
    right: 0;
    background: white;
    z-index: 1;

    .creative-solutions--tabs {
      .creative-solutions--tab {
        padding: 17px 52px;
        cursor: pointer;
      }

      .creative-solutions--tab--active {
        font-weight: 500;
        border-bottom: 2px solid black;
      }
    }

    .creative-solutions--header--content {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      >* {
        display: flex;
        align-items: center;
      }
    }

    .creative-solutions--header--save {
      text-align: center;
      border-radius: 2px;
      padding: 13px 24px;
      font-weight: 500;
      border: 2px solid #9750FF;
      color: #9750FF;
    }

    .creative-solutions--header--logout {
      border: 2px solid black;
      border-radius: 2px;
      color: black;
      padding: 12px 24px;
      font-weight: 500;
    }
  }

  .creative-solutions--actions {
    padding: 48px 16px;
    background: white;
    margin-top: 75px;
    width: 100%;
    max-width: 1200px;

    button {
      display: block;
      border-radius: 2px;
      color: black;
      font-weight: 500;
      width: 100%;
      max-width: 379px;
      text-align: center;

      &:nth-child(1) {
        background: #3AFED1;
        padding: 12px;
      }

      &:nth-child(2) {
        background: white;
        border: 2px solid #000000;
        padding: 12px;
        margin-top: 24px;
      }

      &:disabled {
        cursor: not-allowed;
      }
    }
  }

  .creative-solutions--block {
    padding: 48px 16px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 16px;
    background: white;
    margin-top: 16px;
    width: 100%;
    max-width: 1200px;

    .creative-solutions--block--title {
      font-weight: 600;
      font-size: 36px;
      margin-bottom: 32px;
    }
  }

  .expandable-block {
    color: rgba(0, 0, 0, 0.85);

    .expandable-block--title {
      padding: 20px 32px;
      font-weight: bold;
      font-size: 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .expandable-block--content {
      padding: 24px 32px;
    }

    .expandable-block--open {
      transform: rotate(180deg);
    }
  }

  .creative-solutions--applicant {
    display: flex;
    justify-content: space-between;
    margin-top: 74px;
  }

  .creative-solutions--questions {
    background: white;

    .creative-solutions--questions--block {
      padding: 24px 16px;

      .creative-solutions--questions--block--name {
        color: #3AFED1;
        font-weight: 500;
      }

      .creative-solutions--questions--block--title {
        color: rgba(0, 0, 0, 0.85);
        font-weight: 600;
        font-size: 16px;
        margin-top: 24px;
      }

      .creative-solutions--questions--block--description {
        margin-top: 4px;
        color: rgba(0, 0, 0, 0.65);
        font-size: 12px;
      }

      .custom-datepicker {
        margin-top: 4px;
        color: rgba(0, 0, 0, 0.65);
        font-size: 12px;
        display: flex;
        width: 100%;
        max-width: 472px;
        justify-content: space-between;

        >* {
          width: 30%;
        }
      }

      .creative-solutions--questions--block--select {
        margin-top: 8px;
        max-width: 472px;
        position: relative;

        >*:nth-child(1) {
          height: 45px;

          >div:nth-of-type(1) {
            height: 45px;
            padding-left: 13px;

            >*:nth-child(2) {
              span {
                display: none;
              }
            }
          }
        }

        >*:nth-child(2) {
          position: absolute;
          right: 22px;
          top: 22px;
          pointer-events: none;
        }

        &.select--error {

          >*:nth-child(1) {

            >div:nth-of-type(1) {
              border-color: $red;
            }
          }

          .select--message {
            position: static;
            width: 100%;
            color: $red;
            font-size: 1.07em;
          }
        }
      }

      .creative-solutions--questions--block--add {
        color: #FF5798;
        margin-top: 8px;
      }

      .form-input {
        margin-top: 8px;

        input {
          max-width: 472px;
        }

        .form-input--message {
          text-align: left;
        }
      }
    }

    .creative-solutions--questions--actions {
      margin: 24px 16px 48px;
      display: flex;

      button {
        color: black;
        text-align: center;
        border-radius: 2px;
        padding: 13px 24px;
        font-weight: 500;
        background: #3AFED1;
      }
    }
  }

  .creative-solutions--sidebar {
    width: 100%;
    max-width: 400px;
    padding: 0 16px 96px;
    border: 1px solid #000000;
    border-radius: 2px;
    height: fit-content;
    background: white;

    .creative-solutions--sidebar--header {
      color: rgba(0, 0, 0, 0.45);
      margin: 24px 0 16px;
    }

    .creative-solutions--sidebar--navigation {
      >* {
        color: rgba(0, 0, 0, 0.85);

        &.is-active {
          font-weight: 600;
        }
      }
    }

    .creative-solutions--sidebar--item {
      display: block;
      margin-bottom: 8px;
    }
  }

  .creative-solutions--completed {
    padding: 48px 16px;
    background: white;

    .creative-solutions--completed--title {
      color: rgba(0, 0, 0, 0.85);
      font-weight: 600;
      font-size: 36px;
      line-height: 44px;
    }

    .creative-solutions--completed--note {
      color: rgba(0, 0, 0, 0.65);
      font-size: 12px;
      line-height: 20px;
    }

    button {
      display: block;
      background: #0DB685;
      border: 2px solid #0DB685;
      border-radius: 2px;
      padding: 13px 24px;
      color: white;
      margin: 16px 0;
    }

    img {
      margin-top: 18px;
      width: 100%;
      max-width: 820px;
    }
  }

  ul {
    margin: 0;
  }

  u {
    text-underline-position: under;
  }

  .modal-confirmation {
    .bordered-button {
      border: 2px solid black;
      color: black;
      font-weight: 500;
      padding: 12px 16px;
    }

    .action-button {
      background: #3AFED1;
      color: black;
      font-weight: 500;
      padding: 12px 16px;
    }
  }
}

@media (max-width: $screen-width-md) {
  .creative-solutions {
    .creative-solutions--header {
      top: 59px;
    }

    .creative-solutions--actions {

      button {

        &:nth-child(1) {
          padding: 12px 81px;
        }

        &:nth-child(2) {
          background: #0DB685;
          margin-top: 24px;
          padding: 12px 71px;
        }
      }
    }
  }
}