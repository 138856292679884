.page-account {

  .form-input {
    margin: 9px 0;
  }

  .link-input {
    margin: 24px 0;
  }

  .image-upload {
    margin: 0 auto 20px;
  }

  .suffix-input {
    .form-input {
      margin: 0;
    }
  }

  .navigation-group--title {
    padding-left: 16px;
  }

  .navigation-item--without-border {
    font-size: inherit;
  }

  .navigation-item--content {
    .person--description {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 220px;
    }
  }

  .lock-block {
    display: flex;
    align-items: center;
    font-size: 13px;
    line-height: 1.3;
    color: #999999;
    margin-bottom: 28px;

    img {
      width: 24px;
      height: 24px;
      min-width: 24px;
      margin-right: 16px;
    }
  }

  .submit-button {
    max-width: 564px;
    margin: 0 0 28px;
  }

  .code-select {
    position: relative;
    margin-top: -3px;
    width: 60px;

    .code-select--value {
      position: absolute;
      top: 4px;
      left: 1px;
      z-index: 1;
      background: white;
      width: 58px;
      height: 30px;
      padding: 5px;
      pointer-events: none;
    }

    >* {
      outline: none;
    }

    div[class$="-IndicatorsContainer"] {
      display: none;
    }

    div[class$="-control"] {
      border: 1px solid #C4C4C4 !important;
      box-shadow: none !important;
      border-radius: 2px;
    }

    div[class$="-menu"] {
      width: 200px;
      font-size: 13px;
      line-height: 1.3;
      z-index: 2  ;
    }
  }

  .people {

    .people--item {
      position: relative;
      width: 100%;
      padding: 16px 38px 16px 18px;
      border-bottom: 1px solid $gray-light;

      .people--hover {
        position: absolute;
        top: 16px;
        right: 18px;
        display: none;

        img {
          width: 20px;
          height: 20px;
        }
      }

      &:hover {
        background: $gray-hover;

        .people--hover {
          display: block;
        }
      }
    }
  }

  .invite-members {
    padding: 0 20px;

    .invite-members--title {
      font-weight: bold;
      font-size: 1.28em;
      margin: 16px 0;
    }

    .invite-members--copy {
      display: flex;
      align-items: center;
      width: 100%;
      height: 48px;
      border: 1px solid #C4C4C4;
      border-radius: 2px;

      div {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: calc(100% - 100px);
        padding: 15px 23px;
      }

      button {
        width: 100px;
        border-radius: unset;
        margin: 0;
      }
    }
  }
}