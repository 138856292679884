.tabs {
  position: relative;
  background: white;

  .tabs--content {
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
  }

  .tabs--item {
    display: flex;
    justify-content: center;
    min-width: 120px;
    flex-grow: 1;
    padding: 17px 18px 14px;
    text-align: center;
    border: none;
    background: none;
    outline: none;
    border-bottom: 3px solid white;

    .badge {
      margin-left: 8px;
    }
  }

  .tabs--item--active {
    font-weight: 500;
    border-bottom: 3px solid $yellow-dark;
  }

  .tabs--arrow-left {
    position: absolute;
    top: 10px;
    left: 10px;
    display: none;
  }

  .tabs--arrow-right {
    position: absolute;
    top: 10px;
    right: 10px;
    display: none;

    img {
      transform: rotate(180deg);
    }
  }

  @media (max-width: $screen-width-md) {

    .tabs--arrow-left,
    .tabs--arrow-right {
      display: block;
    }
  }
}