.modal-right {
  z-index: 100000;

  .modal-right--background {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    background: $black;
    opacity: 0.5;
    overflow: hidden;
  }

  .modal-right--dialog {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    max-width: 700px;
    background: $white;
    padding: 30px;
    z-index: 3;
    color: $black;
  }

  .modal-right--title {
    font-weight: bold;
    font-size: 1.71em;
    line-height: 1.3;
    display: flex;
    justify-content: space-between;

    button {
      font-size: 2em;
      height: 24px;
      line-height: 0.5;
      outline: none;
    }
  }

  .modal-right--content {
    height: calc(100vh - 150px);
    margin-right: -30px;
    padding: 0 30px 30px 0;
    margin-top: 18px;
    overflow-x: hidden;
    overflow-y: auto;

    .form-input {
      margin: 18px 0;
    }
  }

  @media (max-width: $screen-width-sm) and (orientation: portrait) {

    .modal-right--dialog {
      left: 0;
      max-width: none;
    }

    .modal-right--content {
      height: calc(100vh - 175px);
    }

    .modal-right--action {
      position: fixed;
      bottom: 30px;
      left: 30px;
      right: 30px;
    }
  }
}

.modal-confirmation {

  .modal-confirmation--background {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    background: $black;
    opacity: 0.5;
    overflow: hidden;
  }

  .modal-confirmation--dialog {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 416px;
    background: $white;
    z-index: 3;
    color: $black;
    padding: 32px 32px 24px;
    box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
    border-radius: 2px;
  }

  .modal-confirmation--content {
    display: flex;

    img {
      width: 22px;
      height: 22px;
      margin-right: 16px;
    }
  }

  .modal-confirmation--title {
    font-size: 1.14em;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.85);
  }

  .modal-confirmation--description {
    color: rgba(0, 0, 0, 0.65);
    margin-top: 8px;
  }

  .modal-confirmation--actions {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    margin-top: 24px;

    >* {
      margin-left: 8px;
    }
  }
}
.modal-central {

  .modal-central--background {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    background: $black;
    opacity: 0.5;
    overflow: hidden;
  }

  .modal-central--dialog {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 520px;
    background: $white;
    z-index: 3;
    border-radius: 2px;
  }

  .modal-central--title {
    font-weight: bold;
    font-size: 1.14em;
    padding: 16px 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #F0F0F0;
    color: rgba(0, 0, 0, 0.85);

    button {
      font-size: 2em;
      line-height: 22px;
      color: rgba(0, 0, 0, 0.65);;
    }
  }

  .modal-central--content {
    padding: 24px;
  }

  .modal-central--actions {
    display: flex;
    justify-content: flex-end;
    padding: 11px 24px;
    border-top: 1px solid #F0F0F0;

    >* {
      margin-left: 8px;
    }
  }
}