.navigation {
  width: 320px;
  min-width: 320px;
  border-right: 1px solid #F0F0F0;
  background: $white;

  .navigation--title {
    padding: 16px;
    font-weight: bold;
    font-size: 1.71em;
    line-height: 1.34;

    button {
      height: 32px;

      img {
        width: 32px;
        height: 32px;
      }
    }
  }

  .navigation--subtitle {
    padding: 16px 16px 8px;
  }

  .navigation--content {
    height: calc(100vh - 64px);
    overflow-x: hidden;
    overflow-y: auto;
  }

  .navigation--content--with-subtitle {
    height: calc(100vh - 120px);
  }
}

.navigation-group {
  width: 100%;

  .navigation-group--title {
    position: relative;
    padding: 16px 16px 16px 36px;
    color: rgba(0, 0, 0, 0.65);
  }

  .navigation-item--title--action {
    position: absolute;
    right: 18px;
    top: 50%;
    transform: translate(0, -50%);

    >* {
      color: #E3C36F;
      font-weight: normal;
      cursor: pointer;
    }
  }

  .navigation-item--title--collapse {
    position: absolute;
    left: 18px;
    top: 50%;
    transform: translate(0, -50%);
    cursor: pointer;

    &.create-webinar--advanced--collapse--closed {
      img {
        transform: rotate(-90deg);
      }
    }
  }
}

.navigation-item {
  position: relative;
  display: flex;
  align-items: center;
  height: 79px;
  padding: 16px;
  border-bottom: 1px solid #F0F0F0;
  cursor: pointer;

  .badge {
    position: absolute;
    top: 42px;
    right: 16px;
  }

  .navigation-item--image {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    min-width: 48px;
    margin-right: 12px;
    border: 1px solid #F0F0F0;
    border-radius: 2px;
    overflow: hidden;

    img {
      max-height: 48px;
    }
  }

  .navigation-item--image--bordered {
    border-radius: 50%;
    border: 1px solid $gray-light;
  }

  .navigation-item--content--action {
    font-size: 1.286em;
    color: $yellow-dark;
  }

  .navigation-item--title {
    font-weight: 500;
    width: 220px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .navigation-item--description {
    width: 180px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-top: 2px;
    color: rgba(0, 0, 0, 0.65);
  }

  .navigation-item--action {
    position: absolute;
    top: 50%;
    right: 18px;
    transform: translate(0, -50%);
  }

  &:hover {
    background: #F7F8F9;
  }

  &.navigation-item--active {
    background: #F7F8F9;
  }

  &.navigation-item--smaller {
    height: 56px;

    .navigation-item--image {
      width: 24px;
      height: 24px;
      border: none;
    }
  }

  &.navigation-item--without-border {
    border-bottom: none;
    font-size: 1.21em;

    .person {
      font-size: inherit;
    }
  }
}

@media (max-width: $screen-width-md) {
  .navigation {
    width: 100%;
    min-width: unset;

    .navigation--content {
      height: calc(100vh - 127px);
      padding-bottom: 60px;
    }
  }
}